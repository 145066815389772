import router from '@/router'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
const appVersion = '1.33'
axios.defaults.headers.common['X-Aude-App-Version'] = appVersion

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 419) {
    localStorage.clear()
    if (router.currentRoute.name !== 'Login') {
      router.push({ name: 'Login' })
    }
  }
  if (error.response.status === 401) {
    if (router.currentRoute.meta.norequiresAuth !== true) {
      // axios.get('/csrf-cookie')
      // axios.get(error.response.config.url)
      localStorage.clear()
      if (router.currentRoute.name !== 'Login') {
        router.push({ name: 'Login' })
      }
    }
  }
  return Promise.reject(error)
})

export default axios
